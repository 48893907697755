@import "~type";

%button {
  border: 2px solid;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  font-size: var(--size-button);
  @extend %bold;

  min-width: 178px;
  min-height: 51px;
}

%whiteboost {
  color: var(--white);

  &:hover,
  &:focus {
    background-color: var(--white);
    color: var(--boostdark);
    outline: 0;
  }
}

%boostwhite {
  color: var(--boostdark);
  &:hover,
  &:focus {
    background-color: var(--boostdark);
    color: var(--white);
    outline: 0;
  }
}