@import "~imports";

@font-face {
  font-family: 'Sharp';
  font-weight: bold;
  src: url("~font/SharpSans-Bold.woff2") format("woff2"),
  url("~font/SharpSans-Bold.woff") format("woff"),
  url("~font/SharpSans-Bold.eot") format("eot");
}

@font-face {
  font-family: 'DonorsChoose';
  font-weight: bold;
  src: url("~font/DonorsChoose-Headline-Bold.woff2") format("woff2"),
  url("~font/DonorsChoose-Headline-Bold.woff") format("woff"),
}

:root {
  /* color */
  --boostdark: #3803C1;
  --gray: #D8D8D8;
  --white: #fff;
  //--workbook: #0062FD;
  --thankyou: #3DA9F3;

  // --boostdark: #1B0260;
  // --boost: #3803C1;

  // --highlighter: #77EEEF;
  // --autumn: #FE7A07;
  --pencil: #FFC808;

  --size-h1: 24px;
  --size-body: 18px;
  --size-h2: 24px;
  --size-button: 12px;
  --nav-height: 68px;
}

@media (min-width: 768px) {
  :root {
    --header-text-align: center;
    --size-h1: 42px;
    --size-body: 21px;
    --size-h2: 36px;
    --size-button: 14px;
  }
}

html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}

.App {
  font-family: 'Montserrat', 'Helvetica', sans-serif;
  font-weight: 400;
}

.AppLoader {
  background-color: var(--pencil);
  color: var(--boostdark);
  @extend %bold;
  height: calc(100vh - var(--nav-height));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}