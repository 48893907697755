@import "imports";

.Nav {
  box-sizing: border-box;
  display: flex;
  background-color: var(--white);
  color: var(--boostdark);
  height: var(--nav-height);
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 -2px 5px 0px var(--boostdark);

  @extend %bold;

  @media (min-width: 500px) {
    font-size: 1.5rem;
  }

  .Nav-inner {
    display: flex;
    align-items: center;
    @extend %max;
    width: 100%;
    padding: 1rem;
  }

  .Nav-separator {
    margin: 0 1rem;
    border-left: 1px solid rgba(52, 52, 52, 0.2);
    height: 100%;
  }

  svg {
    height: 100%;
    margin: 0 auto;
    display: block;

    * {
      fill: var(--boostdark);
    }
  }
}