@import "~imports";

@mixin modalDesktop {
  @media (min-width: $modalBreak) {
    @content;
  }
}

input[type="text"] {
  &:focus {
    outline: 2px solid var(--boostdark);
  }
}

input[type="radio"] {
  display: block;
  appearance: none;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  +* {
    border: 1px solid transparent;
  }
}

input[type="radio"]:not(:checked):hover + *,
input[type="radio"]:focus + * {
  box-shadow: 0 0 4px 0px rgba(0,0,0,0.5)
}
input[type="radio"]:checked + * {
  border-color: var(--boostdark);
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--boostdark);
    opacity: .9;
  }
}

.Modal-inner {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  @include modalDesktop {
    justify-content: center;
  }
}

.Modal-inner-close {
  position: sticky;
  top: 0;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  z-index: 1;
  margin-left: auto;
  background-color: var(--white);
  stroke: var(--boostdark);


}
.Modal-inner-close-button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem;
  display: block;
  margin-left: auto;
  font-size: 100%;
  cursor: pointer;
  &:hover,
  &:focus {
    stroke: #1B0260;
  }

  svg * {
    stroke: inherit;
    stroke-width: 3;
  }
}

.EditGraphic-inner-grid {
  background-color: var(--white);
  position: relative;
  top: var(--nav-height);
  margin-bottom: auto;
  display: grid;
  grid-template-areas:
    "result close"
    "controls controls"
  ;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;


  @include modalDesktop {
    top: var(--nav-height);
    grid-template-columns: 1fr 450px auto;
    grid-template-areas:
      "result controls close"
    ;
    grid-template-rows: 1fr;
  }

  .EditGraphic-result {
    grid-area: result;
    background-color: var(--white);
    z-index: 1;
    padding: 1rem;
    width: 100%;
    position: sticky;
    top: var(--nav-height);

    .Canvas {
      height: 30vh;
    }

    @include modalDesktop {
      padding: 50px;
      border-bottom: 0;
      position: static;
      display: block;
    }
  }

  .EditGraphic-controls {
    grid-area: controls;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    padding: 1rem 0 6rem;

    @include modalDesktop {
      padding: 50px 0;
    }
  }
  .Modal-inner-close {
    grid-area: close;
    align-items: flex-start;
    position: sticky;
    top: var(--nav-height);
    height: 100%;
    @include modalDesktop {
      height: auto;
    }
  }

  .EditGraphic-result,
  .Modal-inner-close {
    border-bottom: 1px solid #D8D8D8;
    @include modalDesktop {
      border-bottom: 0;
    }
  }

  .Canvas {
    align-self: start;
    z-index: 1;
    position: relative;
    height: 100%;
    width: 100%;
    margin-right: .5rem;

    @include modalDesktop {
      min-height: 300px;
      height: 50vh;
    }

    >div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    canvas,
    img {
      object-fit: contain;
      object-position: center center;
      height: 100%;
      width: 100%;
    }
  }
}

.EditGraphic-controls form {
  padding: 0 1em;
  >*+* {
    margin-top: 1.5rem;
  }
}

.EditGraphic-control-label {
  margin-bottom: .5em;
}

.ColorChoices {
  display: flex;
  flex-wrap: wrap;
}
@include modalDesktop {
  .ColorChoices {
    margin: -.25rem;
  }
  .ColorChoices-item {
    margin: .25rem;
  }
}
.ColorChoice-label {
  border-radius: 50%;
  position: relative;
  padding: 3px;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  font-size: 12px;
  @extend %bold;

  >div {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-weight: bold;
  }
}

.Message-input {
  align-items: center;
  display: flex;

  input {
    display: block;
    font-size: 100%;
    border: 0;
    background-color: #F3F3F3;
    padding: .75em;
    margin-right: 1em;
  }
}

.Message-count {
  font-size: var(--size-button);
  font-weight: 300;
}

.Illustration-choices {
  display: grid;
  grid-gap: 1rem;
}

.Illustration-choices--tapestry {
  grid-template-columns: repeat(auto-fit, minmax(120px,1fr));
}

.Illustration-choices--icon {
  grid-template-columns:  repeat(auto-fit, minmax(50px,1fr));
}

.Illustration-choices-item {
  img {
    display: block;
    width: 100%;
  }
}

.Illustration-choices-item-label {
  padding: 5px;
  border-radius: 5px;
}

.Sizes-choices {
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  grid-gap: 1rem;
}
.Sizes-choices-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Size-label {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
  height: 80px;
  width: 80px;
  margin: 0 auto;
}
.Size-label-image {
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.Size-label-text {
  padding-top: 1rem;
  margin-top: auto;
  text-align: center;
  font-size: var(--size-button);
  font-weight: 300;
}
.EditGraphic-submit {
  display: flex;
  margin-top: 14px;
  justify-content: center;

  @include modalDesktop {
    margin-top: 28px;
    //margin-bottom: 28px;
  }
}
.EditGraphic-download {
  @extend %button;
  @extend %boostwhite;
  min-height: 30px;
  min-width: 100px;

  @include modalDesktop {
    min-height: 51px;
    min-width: 178px;
  }
}