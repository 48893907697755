@import "~imports.scss";

img {
  display: block;
  width: 100%;
  height: auto;
}

.Landing {
 min-height: 100vh;

  section + section {
    margin-top: 50px;

    @media (min-width: 768px) {
      margin-top: 150px;
    }
  }
}

.Landing-main {

}

.Landing-header {
  background-color: var(--boostdark);
  color: var(--white);
  text-align: var(--header-text-align);
  padding: 0 1em;
  padding-top: 36px;
  padding-bottom: 36px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.Landing-header-inner {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.Landing-header-heading {
  font-size: var(--size-h1);
  line-height: 1.25;
  @extend %bold;
  margin-top: 0;
}

.Landing-header-text {
  font-size: var(--size-body);
  line-height: 1.71;
}

.Footer {
  background-color: var(--boostdark);
  color: var(--white);
  padding: 50px 1em;
  text-align: center;
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.Footer-heading {
  font-size: var(--size-h2);
  @extend %bold;
}

.Footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Footer-links-item {
  @extend %button;
  @extend %whiteboost;
  margin: 1em;
}

.Footer-links,
.Footer-home {
  margin-top: 40px;
}


.HomeLink {
  font-size: var(--size-body);
  @extend %bold;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--pencil);
  }
}

.Graphics {
  display: grid;
  grid-gap: 27px;
  padding: 0 1rem;
  @extend %max;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3,1fr);
  }
}

.Graphic {
  position: relative;
  cursor: pointer;

  &:after {
    @extend %bold;
    font-size: var(--size-h2);
    align-items: center;
    background-color: var(--boostdark);
    color: var(--white);

    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s;
  }
}
.Graphic:hover,
.Graphic:focus {
  &:after {
    opacity: 1
  }
}

.Graphic--image:hover:after {
  content: 'Customize';
}
.Graphic--wallpaper:hover:after {
  content: 'Pick Size';
}

.Graphic,
.Wallpapers {
  background-color: transparent;
  display: block;
  appearance: none;
  border: 0;
  border-radius: 0;
  font: inherit;
  text-align: unset;
  padding: 0;
}

.Canvas canvas {
  display: block;
  width: 100%;
  height: auto;
}

.Graphics,
.Wallpapers {
  margin-top: 36px;
  margin-bottom: 36px;

  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.Wallpapers {
  @extend %max;
  box-sizing: content-box;
  padding: 0 1rem;
}
.Wallpapers-inner {
  display: grid;
  grid-template-areas:
    "text"
    "image"
  ;
  grid-template-rows: 1fr 1fr;
  background-color: var(--thankyou);

  @media (min-width: 768px) {
    grid-template-areas: "image text";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.Wallpapers-image {
  grid-area: image;
  img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.Wallpapers-text {
  grid-area: text;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 1em;

  @media (min-width: 768px) {
    padding: 35px 40px;
  }
  *+* {
    margin-top: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    @media (min-width: 768px) {
      margin-top: 35px;

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }
}

.Wallpapers-heading {
  font-size: var(--size-h1);
  color: var(--white);
}

.Wallpapers-subheading {
  font-size: var(--size-body);
  line-height: 1.5;
  color: var(--boostdark);
}

.Wallpapers-cta {
  @extend %button;
  @extend %whiteboost;
  margin-top: auto;
}